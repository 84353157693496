import React from 'react'
import Helmet from 'react-helmet'

import Layout from "../../components/layout"
import BgImage from '../../components/bg-image'

const Membranes =  () => (
  <Layout>
    <Helmet title="Pool" />
    <BgImage image="/assets/pool-interiors.jpg" className="top">
      <h1>Waterproof Membranes</h1>
    </BgImage>
    <section className="content">
      <p>
        A waterproofing membrane is the layer of material that is laid over
        swimming pool surface. We are able to bring many swimming pool types
        back to life, or completely replace the waterproof membrane of your
        pool. Options include:
      </p>
      <ul>
        <li>Leaking Pools</li>
        <li>Calcium / Mineral Leaching</li>
        <li>Tiled Pools</li>
        <li>Added Protection</li>
      </ul>
    </section>
  </Layout>
)

export default Membranes;
